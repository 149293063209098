





















































import { Vue, Component, Prop } from "vue-property-decorator";
import { SystemSettingAccountDialogProperties } from "./SystemSettingAccountDialogView.vue";
import { SystemSettingInfoProperties } from "./SystemSettingInfoView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { SnackbarProperties } from "@/components/shared/SnackbarView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import SystemSettingService from "@/services/SystemSettingService";

const SystemSettingAccountDialogView = () => import("./SystemSettingAccountDialogView.vue");

@Component({
    components: { SystemSettingAccountDialogView }
})
export default class SystemSettingAccountView extends Vue {
    @Prop() private properties: SystemSettingInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private systemSettingAccountDialog = new SystemSettingAccountDialogProperties();
    private systemSettingService = new SystemSettingService();

    public get systemSetting() {
        return this.properties.systemSetting;
    }

    public get account() {
        return this.systemSetting.account;
    }

    public accountLinked() {
		const systemSetting = this.properties.systemSetting;
		const account = systemSetting.account;
        return (account?.facebookAccountId ?? 0) !== 0;
    }

    public created() {
        this.properties.events.subscribe('alert-dialog-clicked', this.alertDialogClicked);
        this.properties.events.subscribe('alert-dialog-closed', this.alertDialogClosed);
    }

    public destroyed() {
        this.properties.events.remove('alert-dialog-clicked', this.alertDialogClicked);
        this.properties.events.remove('alert-dialog-closed', this.alertDialogClosed);
    }

    public browseAccount() {
        if (this.accountLinked) {
            return;
        }

        this.systemSettingAccountDialog.visible = true;
    }

    public async linkAccount(account: any) {
        if (this.properties.saving) {
            return;
        }

        this.properties.saving = true;
        try {
            const r = await this.systemSettingService.linkAccount(account.id);
            this.properties.systemSetting = r.data;
            this.systemSettingAccountDialog.visible = false;

            var msg = this.$t("message.link-account-successful");
            AlertDialogProperties.saved(this.alertDialog, null, msg);
            this.alertDialog.visible = true;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.properties.saving = false;
        }
    }

    public alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        if (tag && tag.command === "link-account") {
            if (button === this.$t("text.yes")) {
                this.linkAccount(tag.account);
            }
        }
    }

    public alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag && tag.command === "accounts-load-error") {
            this.systemSettingAccountDialog.visible = false;
        }
    }
}
