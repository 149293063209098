





























import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { SnackbarProperties } from "@/components/shared/SnackbarView.vue";
import FacebookService from "@/services/FacebookService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import DateUtil from "@/utilities/DateUtil";
import RouteUtil from "@/utilities/RouteUtil";

class SystemSettingAccountDialogProperties {
    visible: boolean = false;
}

export { SystemSettingAccountDialogProperties };

@Component({
    data: () => ({
        name: "account-dialog",
        loaded: false,
        accounts: [],
        selected: null
    })
})
export default class SystemSettingAccountDialogView extends Vue {
    @Prop() private properties: SystemSettingAccountDialogProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private snackbar: SnackbarProperties;
    private facebookService = new FacebookService();

    public mounted() {
        this.load();
    }

    public async checkToken() {
        var r = await this.facebookService.checkToken(false, true);
        if (r.data.valid) {
            var d = DateUtil.between(new Date(r.data.expiresAt), new Date(), "d");
            if (d <= 7) {
                await this.facebookService.extendToken();
                return true;
            }
        } else {
            var redirectUrl = RouteUtil.getUrlWithoutPathname(true);
            redirectUrl += "authenticating?type=refresh&from=system-setting";
            var r = await this.facebookService.loginUrl(redirectUrl, true);
            window.location.href = r.data;
            return false;
        }
        return true;
    }

    public async load() {
        try {
            if (await this.checkToken()) {
                var r = await this.facebookService.findAccounts();
                
                if (r.data.length === 0) {
                    SnackbarProperties.reset(this.snackbar);
                    var msg = this.$t('message.no-account-found');
                    this.snackbar.message = msg;
                    this.snackbar.timeout = 4000;
                    this.snackbar.alignment='top-center';
                    this.snackbar.visible = true;
                    this.properties.visible = false;
                }

                this.$data.accounts = r.data;
                this.$data.loaded = true;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "accounts-load-error" };
                this.alertDialog.visible = true;
            }
        }
    }

    public confirmation() {
        if ((this.$data.selected ?? -1) === -1) {
            var m = this.$t("message.account-not-selected");
            AlertDialogProperties.info(this.alertDialog, null, m);
            this.alertDialog.visible = true;
        } else {
            if (this.$data.selected >= this.$data.accounts.length) {
                return;
            }

            var account = this.$data.accounts[this.$data.selected];
            var t = this.$t("title.confirmation");
            var m = this.$t("message.link-account-confirmation", [
                account.name
            ]);
            AlertDialogProperties.standard(this.alertDialog, t, m);
            this.alertDialog.buttons = [
                this.$t("text.yes"),
                this.$t("text.no")
            ];
            this.alertDialog.primaryButton = this.$t("text.yes");
            this.alertDialog.tag = { command: "link-account", account };
            this.alertDialog.visible = true;
        }
    }

    public close() {
        this.properties.visible = false;
    }
}
